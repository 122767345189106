import { useEffect, useState } from "react";
import { Read } from "../../../api/Trophies";
import { useAuth } from "../../../Context/AuthContext";
import TrophyDisplay from "../../../components/TrophyDisplay/TrophyDisplay";
import {useNavigate} from "react-router-dom";

export default function Trophies() {
    const { id } = useAuth();
    const [trophies, setTrophies] = useState({});
    const navigate = useNavigate()

    const fetchData = async () => {
        // Uncomment the line below to use the actual API when testing the real implementation
         const response = await Read(id);


        // Mock data for testing
        /*const response = {
            global: [
                { id: 1, user_id: id, name: "2024 GPB Champion", awarded_at: "2024-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },
                { id: 2, user_id: id, name: "2023 GPB Silver",  awarded_at: "2023-12-15T20:35:01.214271" },

            ],
            national: [
                { id: 3, user_id: id, name: "National Winner", position: 1, awarded_at: "2024-10-10T15:20:00.000000" },
                { id: 4, user_id: id, name: "National Runner-Up", position: 2, awarded_at: "2023-10-10T15:20:00.000000" },
            ],
            league: [
                { id: 5, user_id: id, name: "League Champion", position: 1, awarded_at: "2024-05-15T12:00:00.000000" },
            ],
            unknown: [],
        };*/

        setTrophies(response);
    };

    useEffect(() => {
        if(!id)navigate('/profile')
        fetchData();
    }, [id]);

    return <>
        <button onClick={()=>navigate('/profile')}>Go back</button>
        <TrophyDisplay trophies={trophies} />
    </>
}
