import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LeagueAPI from "../../api/LeagueAPI";
import { useAuth } from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faUserPlus, faSignOutAlt, faCopy, faTrash} from "@fortawesome/free-solid-svg-icons";
import styles from "./LeagueDetailsPage.module.css";
import {Read} from "../../api/BingoCard";
import Modal from "../../components/Modal/Modal";
import {RenderBingoCard} from "../../components/BingoCard/BingoCard";

function LeagueDetailPage() {
    const { id } = useParams();
    const [league, setLeague] = useState(null);
    const [userLeague, setUserLeague] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [joining, setJoining] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [cardData, setCardData] = useState(null)

    const { id: userId } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        LeagueAPI.getLeagueById(id)
            .then(setLeague)
            .catch(() => setError("League not found"));

        LeagueAPI.getLeagueLeaderboard(id).then(setLeaderboard);
        console.log(userId)
        if (userId && !userLeague) {
            LeagueAPI.getUserLeague(userId)
                .then((l)=>{
                    setUserLeague(l)
                    console.log(l);
                })
                .catch(() => setUserLeague(null));
        }
        setLoading(false);
    }, [id, userId, userLeague]);

    function handleJoin() {
        if (!userId) return;
        setJoining(true);
        LeagueAPI.joinLeague(userId, league.id)
            .then(() => setUserLeague(league))
            .catch(() => setError("Could not join league"))
            .finally(() => setJoining(false));
    }

    function handleLeave() {
        LeagueAPI.leaveLeague(userId)
            .then(() => setUserLeague(null))
            .catch(() => setError("Could not leave league"));
    }

    async function fetchCard(id) {
        try {
            const reponse = await Read(id);
            setCardData(reponse)

        } catch (err) {

        }
    }

    if (loading) return <div className={styles.loading}>Loading league details...</div>;
    if (error) return <div className={styles.error}>{error}</div>;

    const copyToClipboard = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text).then(() => {
                alert("Copied to clipboard!");
            }).catch(err => {
                console.error("Clipboard write failed", err);
            });
        } else {
            // Fallback method for older devices
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            alert("Copied to clipboard!");
        }
    };

    return (
        <>
            <div onClick={() => navigate(-1)} style={{display: "flex", margin: "10px 5px", gap: "10px", alignItems: "center"}} >
                <FontAwesomeIcon icon={faArrowLeft} size={"lg"}/>
                <p style={{fontSize: "20px"}}>Back to leagues</p>
            </div>
            {/* Banner Section */}
            <div className={`${styles.banner} Block`}>
                {/* Left Content */}
                <div className={styles.bannerContent}>
                    <h2 className={styles.leagueTitle}>{league?.name}</h2>
                    {!league?.is_public && (
                        <h4 className={styles.leagueCode}>
                            Code: <span>{league?.invite_code}</span>
                            <span style={{padding: "5px"}} className={styles.copyButton}>
                                <FontAwesomeIcon icon={faCopy} color={"var(--black)"} onClick={() => copyToClipboard(league.invite_code)}/>
                            </span>
                        </h4>
                    )}
                    <p className={styles.leagueDesc}>{league?.description}</p>
                    <p className={styles.leagueMeta}>
                        🌍 {league?.country_code} • {league?.is_public ? "🟢 Public" : "🔒 Private"}
                    </p>
                </div>

                {/* Right Actions */}
                {userId && (
                    <div className={styles.bannerActions}>
                        {userLeague?.id === league?.id ? (
                            <button onClick={handleLeave} className={styles.leaveButton}>
                                <FontAwesomeIcon icon={faSignOutAlt}/> Leave League
                            </button>
                        ) : !userLeague && (
                            <button onClick={handleJoin} className={styles.joinButton} disabled={joining}>
                                {joining ? "Joining..." : (
                                    <>
                                        <FontAwesomeIcon icon={faUserPlus}/> Join League
                                    </>
                                )}
                            </button>
                        )}

                        {/* Show Delete button only if the user is the creator */}
                        {userId === (league?.creator_id ?? -1) && (
                            <button
                                onClick={() => {
                                    LeagueAPI.deleteLeague(league.id).then(() => navigate(-1));
                                }}
                                className={styles.deleteButton}
                            >
                                <FontAwesomeIcon icon={faTrash}/> Delete
                            </button>
                        )}
                    </div>
                )}
            </div>


            {/* Leaderboard Table */}
            <div className={styles.leaderboardContainer}>
                <h2 className={styles.tableTitle}>League Leaderboard</h2>
                <table className={styles.leaderboardTable}>
                    <thead>
                    <tr>
                        <th style={{width: "25%"}}>Pos</th>
                        <th>Username</th>
                        <th style={{width: "25%"}}>Points</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leaderboard.map((player, index) => (
                        <tr key={player.id} className={styles[`position${index + 1}`]}
                            onClick={() => {
                                fetchCard(player.card_id)
                            }}
                        >
                            <td className={styles.rank}>{index + 1}</td>
                            <td>{player.user_name}</td>
                            <td>{player.total_points}</td>
                        </tr>
                    ))}
                    <tr style={{height: "39px"}}>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {cardData && <Modal closeModal={() => setCardData(null)}><RenderBingoCard card={cardData}/> </Modal>}
        </>
    );
}

export default LeagueDetailPage;
