import React from 'react';
import styles from './TrophyDisplay.module.css';

const TrophyDisplay = ({ trophies }) => {

    const renderTrophyShelf = (type, trophyList) => (
        <div key={type} className={styles.trophyShelf}>
            <h3 className={styles.shelfTitle}>
                {type.charAt(0).toUpperCase() + type.slice(1)} Trophies
            </h3>
            <div className={styles.shelfWrapper}>
                <div className={styles.trophySlider}>
                    {trophyList.length > 0 ? (
                        trophyList.map((trophy) => (
                            <div key={trophy.id} className={styles.trophyCard}>
                                <div className={styles.trophyName}>{trophy.name}</div>
                                <div className={styles.trophyDetails}>
                                    <span>
                                        Awarded: {new Date(trophy.awarded_at).toLocaleDateString()}
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={styles.noTrophies}>No trophies in this category.</p>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.cabinet}>
            {Object.keys(trophies).map((type) => renderTrophyShelf(type, trophies[type]))}
        </div>
    );
};

export default TrophyDisplay;
