import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CreateLeague.module.css"; // Import CSS module
import LeagueAPI from "../../api/LeagueAPI";
import {useAuth} from "../../Context/AuthContext";
import CountrySelector from "../CountrySelector/CountrySelector";

export default function CreateLeague() {
    const { id: userId } = useAuth(); // Get logged-in user ID
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        country_code: "",
        is_public: true
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name) {
            setError("League name and country are required.");
            return;
        }
        setLoading(true);
        setError(null);

        const leagueData = {
            ...formData,
            creator_id: userId
        };

        const response = await LeagueAPI.createLeague(leagueData);
        console.log(response)
        setLoading(false);

        if (response.status === "success") {
            navigate(response.league_id)
        } else {
            setError(response.message || "Failed to create league.");
        }
    };

    return (
        <>
            <h1 className={styles.title}>Create a League</h1>

            {error && <p className={styles.error}>{error}</p>}

            <form className={styles.form} onSubmit={handleSubmit}>
                <label className={styles.label}>
                    League Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={styles.input}
                        required
                    />
                </label>

                <label className={styles.label}>
                    Description:
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={styles.textarea}
                    />
                </label>

                <label className={styles.label}>
                    Nationality (optional):
                    <CountrySelector
                        value={formData.country_code}
                        onChange={(value) => setFormData((prev) => ({...prev, country_code: value}))}
                    />
                </label>

                <label className={styles.checkbox}>
                    <input
                        type="checkbox"
                        name="is_public"
                        checked={formData.is_public}
                        onChange={handleChange}
                    />
                    Public League (Anyone can join)
                </label>

                <button type="submit" className={styles.button} disabled={loading}>
                    {loading ? "Creating..." : "Create League"}
                </button>
            </form>
        </>
    );
}
