import { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { Create, Read } from "../api/BingoCard";
import BingoCard from "../components/BingoCard/BingoCard";
import Modal from "../components/Modal/Modal";
import LoginRedirect from "../components/LoginRedirect/LoginRedirect";
import CardList from "../components/CardList/CardList";
import {Helmet} from "react-helmet-async";
import BoostDisplay from "../components/BoostDisplay/BoostDisplay";
import SuggestionNav from "../components/SuggestionNav/SuggestionNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLeftRight, faRightLeft} from "@fortawesome/free-solid-svg-icons";

export default function BingoCardPage() {
    const { id } = useAuth(); // Get the user ID from AuthContext
    const [selectedCard, setSelectedCard] = useState(null);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to create a new bingo card and fetch its data
    const create = async (event) => {
        setLoading(true);
        try {
            await Create(id, event); // Use `id` from AuthContext as `userId`
            await fetchBingoCards();

        } catch (error) {
            console.error("Failed to create bingo card:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch a specific bingo card's data
    const fetchCard = async (card_id) => {
        setLoading(true);
        try {
            const card = await Read(card_id);
            setSelectedCard(card);

        } catch (error) {
            console.error("Failed to fetch bingo card:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBingoCards = async () => {
        if (id) {
            setLoading(true);
            try {
                const userCards = await Read(null, id); // Fetch all bingo cards for the user
                //console.log(userCards);
                setCards(userCards);

            } catch (error) {
                console.error("Failed to fetch bingo cards for user:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    // Fetch all bingo cards for the user on component mount
    useEffect(() => {

        fetchBingoCards();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>Your Bingo Cards - GrandPrixBingo</title>
                <meta
                    name="description"
                    content="Manage and track your F1 bingo cards. Note down events and earn points for your leaderboard ranking."
                />
                <meta
                    name="keywords"
                    content="F1 bingo cards, points, GrandPrixBingo, track events"
                />
                <link rel="canonical" href="https://GrandPrixBingo.com/cards" />
            </Helmet>
            {!id && (
                <Modal>
                    <LoginRedirect />
                </Modal>
            )}
            {id && !selectedCard && (
                <>
                <CardList cards={cards} fetchCard={fetchCard} create={create} />
                    {/*<SuggestionNav/>*/}
                </>
            )}
            {id && selectedCard && (
                <SelectedCard />
            )}

        </>
    );

    // Component to display the selected bingo card and the "Back" button
    function SelectedCard() {
        return (
            <div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
                    <button onClick={() => setSelectedCard(null)} style={{whiteSpace: "nowrap"}}>Back to Cards</button>
                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", flexDirection: "row-reverse"}}>
                    <div className="Block" style={{marginLeft: "25px", marginBottom: 0}}>
                        <h5><FontAwesomeIcon icon={faRightLeft}/> Swaps: {selectedCard.swap_count}</h5>

                    </div>
                    <BoostDisplay id={id}/>
                    </div>
                </div>
                <BingoCard data={selectedCard.bricks} id={selectedCard.card_id} selectedCard={selectedCard} fetchCard={fetchCard}/>
            </div>
        );
    }
}
