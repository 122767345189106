import Select from "react-select";
import countryList from "react-select-country-list";
import styles from "./CountrySelector.module.css"; // Import CSS module

export default function CountrySelector({ value, onChange }) {
    // Generate country options with flags
    const countryOptions = [
        { value: null, label: '🌍 Not Chosen' }, // Default option
        ...countryList().getData().map((country) => ({
            value: country.value,
            label: (
                <div className={styles.countryOption}>
                    <img
                        src={`https://flagcdn.com/w40/${country.value.toLowerCase()}.png`}
                        alt={country.label}
                        className={styles.flag}
                    />
                    {country.label}
                </div>
            ),
        })),
    ];

    return (
        <Select
            value={countryOptions.find((option) => option.value === value) || countryOptions[0]}
            onChange={(selectedOption) => onChange(selectedOption?.value)}
            options={countryOptions}
            placeholder="Select a country"
            isClearable
            className={styles.select}
        />
    );
}
