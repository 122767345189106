import Modal from "../Modal/Modal";
import {useEffect, useState} from "react";
import {FetchByEvent} from "../../api/Bricks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLeftRight, faRightLeft, faRightLong} from "@fortawesome/free-solid-svg-icons";
import {swapBricks} from "../../api/BingoCard";

export default function Swapper({open, close, cardId, cell, refresh}) {
    const [bricks, setBricks] = useState([]);
    const [filteredBricks, setFilteredBricks] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [swap, setSwap] = useState(null);
    useEffect(() => {
        if(open){
            fetchData()
        }
    },[open])

    const performSwap = async() => {
        await swapBricks(cardId, swap.brick_id, cell.position);
        refresh();
        close();
    }

    const fetchData = async () => {
        const res = await FetchByEvent(2, cardId);
        setBricks(res);
        setFilteredBricks(res)
    }

    useEffect(() => {
        if(searchValue.length > 2){
            const filteredBricks = bricks.filter(brick => (brick.title.toLowerCase().includes(searchValue)) || brick.description.toLowerCase().includes(searchValue));
            setFilteredBricks(filteredBricks);
        }else{
            setFilteredBricks(bricks)
        }
    }, [searchValue]);

    const getBackGround = (points) => {
        switch(points){
            case 100: return "var(--black)";
            case 200: return "var(--silver)";
            case 400: return "var(--gold)";
        }
    }

    function confirmModal() {
        return <>
            {swap &&
                <Modal closeModal={() => setSwap(null)}>
                    <h3 style={{textAlign: "center", marginBottom: "15px", fontWeight: "bold"}}>Confirm Swap</h3>

                    {/* Swap Preview Section */}
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        padding: "15px",
                    }}>
                        {/* Current Brick */}
                        <div style={{
                            backgroundColor: "var(--gray)",
                            padding: "12px",
                            borderRadius: "8px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                            flex: "1",
                            textAlign: "center"
                        }}>
                            <h5 style={{margin: "5px 0", fontSize: "16px", fontWeight: "bold"}}>{cell.title}</h5>
                            <p style={{
                                fontSize: "12px",
                                color: "#555",
                                fontWeight: "normal",
                                marginBottom: "8px"
                            }}>{cell.description}</p>
                            <span style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                backgroundColor: "var(--black)",
                                color: "white"
                            }}>
                {cell.points} P
            </span>
                        </div>

                        {/* Swap Icon */}
                        <FontAwesomeIcon icon={faRightLong} size="lg" style={{color: "#555"}}/>

                        {/* New Brick */}
                        <div style={{
                            backgroundColor: "var(--light-gray)",
                            padding: "12px",
                            borderRadius: "8px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                            flex: "1",
                            textAlign: "center"
                        }}>
                            <h5 style={{margin: "5px 0", fontSize: "16px", fontWeight: "bold"}}>{swap.title}</h5>
                            <p style={{
                                fontSize: "12px",
                                color: "#555",
                                fontWeight: "normal",
                                marginBottom: "8px"
                            }}>{swap.description}</p>
                            <span style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                backgroundColor: "var(--black)",
                                color: "white"
                            }}>
                {swap.points} P
            </span>
                        </div>
                    </div>

                    {/* Buttons Section */}
                    <div style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                        marginTop: "15px",
                    }}>
                        <button
                            onClick={() => setSwap(null)}
                            style={{
                                backgroundColor: "var(--silver)",
                                padding: "10px 15px",
                                border: "none",
                                borderRadius: "6px",
                                cursor: "pointer",
                                fontSize: "14px",
                                transition: "0.3s",
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={performSwap}
                            style={{
                                padding: "10px 15px",
                                border: "none",
                                borderRadius: "6px",
                                cursor: "pointer",
                                fontSize: "14px",
                                transition: "0.3s",
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </Modal>

            }
        </>;
    }

    function  choseSwap() {
        return (
            <Modal closeModal={close}>
                {cell.status ? (
                    <p>This cell isn't swappable</p>
                ) : (<div style={{padding: "20px", width: "100%", maxWidth: "450px", textAlign: "center"}}>
                        <h1 style={{fontSize: "1.5rem", fontWeight: "bold", marginBottom: "15px", color: "#333"}}>
                            Choose a brick to swap with:
                        </h1>
                        <input onChange={(e) => {
                            setSearchValue(e.target.value.toLowerCase())
                        }} placeholder="Search..." value={searchValue}/>
                        <div style={{
                            maxHeight: "50vh",
                            overflowY: "auto",
                            padding: "10px",
                            borderRadius: "8px",
                            background: "var(--gray)",
                            boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.1)",
                            border: "1px solid #ddd"
                        }}>
                            {filteredBricks.length > 0 ? (
                                filteredBricks.map((brick) => (
                                    <div
                                        key={brick.brick_id}
                                        style={{
                                            padding: "12px 15px",
                                            margin: "8px 0",
                                            background: "var(--white)",
                                            border: "1px solid #ddd",
                                            borderRadius: "6px",
                                            cursor: "pointer",
                                            transition: "0.3s",
                                            textAlign: "left",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                            justifyContent: "space-between",
                                        }}
                                        onClick={() => setSwap(brick)}
                                    >
                                        <h4 style={{fontSize: "1rem", margin: "0", color: "#444"}}>
                                            {brick.title}
                                        </h4>
                                        <p style={{
                                            fontSize: "1rem",
                                            margin: "0",
                                            color: getBackGround(brick.points)
                                        }}>{brick.points}p</p>
                                    </div>
                                ))
                            ) : (
                                <p style={{color: "#888", fontSize: "1rem", marginTop: "10px"}}>No cards available.</p>
                            )}
                        </div>
                    </div>
                )}
            </Modal>
        );
    }

    if(open){
        return swap ? confirmModal() :choseSwap()

    }
}
