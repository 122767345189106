import { useState } from "react";
import styles from "./BingoCard.module.css";
import Modal from "../Modal/Modal";
import { Update, FetchByEvent} from "../../api/Bricks";
import {UpdateBrickCard} from "../../api/BingoCard";
import {useAuth} from "../../Context/AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightLeft, faRocket} from "@fortawesome/free-solid-svg-icons";
import Swapper from "../Swapper/Swapper";
import BoostDisplay from "../BoostDisplay/BoostDisplay";

export function RenderBingoCard({card, setExpanded = null}) {
    const {card_id, points, bricks} = card;
    const {id} = useAuth()
    return <>
        <div className={styles.header}>
            <h2>Bingo Card #{card_id}</h2>
            <p style={{marginBottom: "20px"}}>Score: {points} pts</p>
        </div>
        <div className={styles.bingoCard}>
            {bricks.map((cell) => (
                <BingoCell key={cell.brick_id} cell={cell} onClick={() => {
                    setExpanded && setExpanded(cell)
                }}/>
            ))}
        </div>
    </>;
}

export default function BingoCard({selectedCard, fetchCard}) {
    const [expanded, setExpanded] = useState(null);
    const {id} = useAuth()

    const closeModal = () => setExpanded(null);

    return (
        <div className={styles.cardContainer}>
            <RenderBingoCard card={selectedCard} setExpanded={setExpanded}/>
            {expanded && (
                <Modal closeModal={closeModal}>
                    <ExpandedCell cell={expanded} id={id} card={selectedCard.card_id} fetchCard={fetchCard} boosts={selectedCard.boosts} swaps={selectedCard.swap_count}/>
                </Modal>
            )}
        </div>
    );
}

const flagCell = async (updatedItem) => {
    const item = {
        brick_id: updatedItem.brick_id,
        flagged: 1,
    };
    const result = await Update(item);
    if (result.status !== "success") {
        alert("Failed to save changes.");
    }
};

function BingoCell({ cell, onClick }) {
    const cellClass = `
        ${styles.cell} 
        ${cell.status ? styles["status-true"] : ""} 
        ${cell.boost_level === 2 ? styles["boost-2"] : cell.boost_level === 3 ? styles["boost-3"] : ""}
    `;

    return (
        <div className={cellClass}
             style={{
                 gridArea: `auto / auto / span 1 / span 1`,
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 position: 'relative', // Allows absolute positioning inside
                 textAlign: 'center'
             }}
             onClick={onClick}
        >
            <span>{cell.title}</span>
            <span
                style={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    backgroundColor: getBadgeColor(cell.points), // Dynamic badge color
                    color: getTextColor(cell.points),
                    padding: '2px 6px',
                    borderRadius: '8px',
                    fontSize: "clamp(0.4rem, 1.4vw, 0.9rem)",
                    fontWeight: 'bold',
                    minWidth: '20px',
                    textAlign: 'center'
                }}
            >
        {cell.points}p
    </span>
        </div>


    );
}

const getBadgeColor = (points) => {
    switch (points) {
        case 100: return 'var(--black)'; // Silver
        case 200: return 'var(--silver)'; // Gold
        case 400: return 'var(--gold)'; // Gray
        default: return '#D3D3D3';  // Default light gray
    }
};

const getTextColor = (points) => {
    switch (points) {
        case 100: return 'var(--white)';
        case 200: return 'var(--black)';
        case 400: return 'var(--black)';
        default: return 'var(--black)';
    }
}


function ExpandedCell({cell, id, card, fetchCard, boosts, swaps}) {
    const [data, setData] = useState(cell);
    const [swap, setSwap] = useState(null);

    //console.log(swaps)

    const boost = async () => {
        try {
            const response = await UpdateBrickCard(id, card, {...data, boost_level: 2});
            fetchCard(card);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{width: "100%"}}>
            <div style={{display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <h3 className={styles.title}>{data.title}</h3>
                <span className={`${styles.statusLabel} ${data.status ? styles.claimed : styles.locked}`}>
                    {data.status ? "Claimed" : "Pending"}
                </span>
            </div>

            <p className={styles.description} style={{whiteSpace: "pre-wrap"}}>
                {data.description}
            </p>
            <p style={{marginBottom: "10px"}}>{data.points} points</p>

            <div className={styles.status}>
                {data.boost_level > 1 ? (
                    <span className={`${styles.boost} ${data.boost_level === 2 ? styles.silver : styles.gold}`}>
                        Boost: {data.boost_level}x
                    </span>
                ) : !data.status && (
                    <div style={{display: "flex", justifyContent: "space-evenly", flex: 1}}>
                        <button className={`${styles.boost} hoverable`} onClick={boost}>
                            <FontAwesomeIcon icon={faRocket}/> Boost
                        </button>
                        <button className={`${styles.boost} hoverable`} onClick={() => setSwap(cell)} disabled={swaps === 0}>
                            <FontAwesomeIcon icon={faRightLeft}/> Swap
                        </button>
                    </div>
                )}
            </div>

            <Swapper close={() => setSwap(null)} cell={cell} cardId={card} open={swap} refresh={()=>fetchCard(card)}/>
        </div>
    );
}

