import Modal from "../Modal/Modal";
import styles from "./HelpModal.module.css";

export default function HelpModal({ title, description, actionMethod, actionLabel = "Action", closeModal }) {
    return (
        <Modal closeModal={closeModal}>
            <div className={styles.helpModal}>
                <h2 className={styles.modalTitle}>{title}</h2>
                <p
                    className={styles.modalDescription}
                    dangerouslySetInnerHTML={{
                        __html: description.replace(/\n/g, "<br />"), // Replace `\n` with `<br />`
                    }}
                />
                <div className={styles.modalActions}>
                    {actionMethod && (
                        <button onClick={actionMethod} className={styles.actionButton}>
                            {actionLabel}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
