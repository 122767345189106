import styles from "./CardList.module.css";
import { useState, useEffect } from "react";
import CountdownTimer from "../CountDownTImer/CountdownTimer";

export default function CardList({ cards, fetchCard, create }) {
    const now = new Date();

    // Group events based on time relative to start and end_time
    const activeEventsWithoutCards = []; // Active events without a card
    const activeEventsWithCards = [];    // Active events with a card
    const expiredCards = [];             // Expired events (only if the user had a card)
    let nextEvent = null;                // The soonest upcoming event (has not started yet)

    cards.forEach((item) => {
        const eventStart = new Date(item.start);
        const eventEnd = new Date(item.end_time);
        const hasCard = item.card_id !== null;

        if (eventEnd < now) {
            // Expired event: Show only if the user had a card
            if (hasCard) {
                expiredCards.push(item);
            }
        } else if (eventStart > now) {
            // Upcoming event (not started yet)
            if (!nextEvent || eventStart < new Date(nextEvent.start)) {
                nextEvent = item;
            }
        } else {
            // Active event: Started but not ended
            if (hasCard) {
                activeEventsWithCards.push(item);
            } else {
                activeEventsWithoutCards.push(item);
            }
        }
    });

    // Countdown timer state
    const [countdown, setCountdown] = useState("");

    useEffect(() => {
        if (activeEventsWithCards.length === 0 && activeEventsWithoutCards.length === 0 && nextEvent) {
            const interval = setInterval(() => {
                const now = new Date();
                const eventStart = new Date(nextEvent.start);
                const diff = eventStart - now;

                if (diff > 0) {
                    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((diff / (1000 * 60)) % 60);
                    const seconds = Math.floor((diff / 1000) % 60);
                    setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                } else {
                    clearInterval(interval);
                    setCountdown("Event has started!");
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [activeEventsWithCards, activeEventsWithoutCards, nextEvent]);

    return (
        <div className={styles.cardList}>
            <h2 className={styles.title}>Your Bingo Cards</h2>

            {/* Show countdown if there are no active events */}
            {activeEventsWithCards.length === 0 &&
                activeEventsWithoutCards.length === 0 &&
                nextEvent && (
                    <CountdownTimer startTime={nextEvent.start} eventName={nextEvent.event_name} />
                )}

            {/* Active events without a card (available to join) */}
            {activeEventsWithoutCards.length > 0 && (
                <>
                    <h3 className={styles.sectionTitle}>Available Events</h3>
                    {activeEventsWithoutCards.map((item) => (
                        <div
                            key={item.event}
                            className={`hoverable ${styles.cardItem}`}
                            onClick={() => create(item.event)}
                        >
                            <p><strong>{item.event_name}</strong></p>
                            <p>Starts: {new Date(item.start).toLocaleDateString()}</p>
                        </div>
                    ))}
                </>
            )}

            {/* Active events with a user card */}
            {activeEventsWithCards.length > 0 && (
                <>
                    <h3 className={styles.sectionTitle}>Your Active Cards</h3>
                    {activeEventsWithCards.map((item) => (
                        <div
                            key={item.card_id}
                            className={`hoverable ${styles.cardItem}`}
                            onClick={() => fetchCard(item.card_id)}
                        >
                            <p><strong>Card #{item.card_id}</strong></p>
                            <p>Created: {new Date(item.created_at).toLocaleDateString()}</p>
                            <p><strong>Points: {item.points}</strong></p>
                        </div>
                    ))}
                </>
            )}

            {/* Expired events (only show if the user had a card) */}
            {expiredCards.length > 0 && (
                <>
                    <h3 className={styles.sectionTitle}>Expired Cards</h3>
                    {expiredCards.map((item) => (
                        <div
                            key={item.card_id}
                            className={`${styles.cardItem} ${styles.expiredCard}`}
                            style={{ cursor: "not-allowed", opacity: 0.5 }}
                        >
                            <p><strong>Card #{item.card_id}</strong></p>
                            <p>Created: {new Date(item.created_at).toLocaleDateString()}</p>
                            <p><strong>Points: {item.points}</strong></p>
                            <p className={styles.expiredText}>Event Ended</p>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}
