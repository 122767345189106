import { useEffect, useState } from "react"
import {FetchByEvent, Latest} from "../../api/Bricks"

import styles from './HomePage.module.css';
import {Helmet} from "react-helmet-async";
import {LeaderBoard} from "../../api/BingoCard";
import BrickRequests from "../../components/BrickRequests/BrickRequests";
import {useNavigate} from "react-router-dom";
import SuggestionNav from "../../components/SuggestionNav/SuggestionNav";
import CountdownTimer from "../../components/CountDownTImer/CountdownTimer";
import Modal from "../../components/Modal/Modal";
import Swapper from "../../components/Swapper/Swapper";
import LeaderboardPage from "../LeaderboardPage";

function LatestEvents({ events, podium }) {
    return (
        <>
            <Helmet>
                <title>GrandPrixBingo - F1 Bingo Service</title>
                <meta
                    name="description"
                    content="Explore the latest events and learn about GrandPrixBingo, the ultimate F1 bingo app where you can compete, track, and compare with friends."
                />
                <meta
                    name="keywords"
                    content="F1, Formula 1, bingo, F1 events, racing, leaderboard"
                />
                <link rel="canonical" href="https://GrandPrixBingo.com/" />
            </Helmet>
            {podium ? <>
                    <h2 className={styles.eventsHeader}>2024 Results</h2>
                    <div className={styles.podiumContainer}>
                        {[2, 1, 3].map((rank) => {
                            const participants = podium.filter((item) => item.rank === rank);

                            if (participants.length > 0) {
                                return (
                                    <div
                                        key={rank}
                                        className={`${styles.podiumCard} ${
                                            rank === 1
                                                ? styles.firstPlace
                                                : rank === 2
                                                    ? styles.secondPlace
                                                    : styles.thirdPlace
                                        }`}
                                    >
                                        {/* Names positioned above the block */}
                                        <div className={styles.podiumNames}>
                                            {participants.map((participant, index) => (
                                                <div
                                                    key={participant.id}
                                                    className={styles.podiumName}
                                                >
                                                    {participant.user_name}
                                                </div>
                                            ))}
                                        </div>

                                        {/* Podium rank and points */}
                                        <div
                                            className={`${styles.podiumRank} ${
                                                rank === 1
                                                    ? styles.firstPlaceRank
                                                    : rank === 2
                                                        ? styles.secondPlaceRank
                                                        : styles.thirdPlaceRank
                                            }`}
                                        >
                                            {rank === 1 ? "1st" : rank === 2 ? "2nd" : "3rd"}
                                        </div>

                                        <div className={styles.podiumPoints}>
                                            {participants[0].total_points} pts
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>


                </>

                : <>
                    <h2 className={styles.eventsHeader}>Latest Events</h2>
                    <div className={styles.eventsContainer}>
                        {events.length > 0 ? (
                            events.map(event => (
                                <div key={event.brick_id} className={styles.eventCard}>
                                    <div className={styles.eventHeader}>
                                        <h4 className={styles.eventTitle}>{event.title}</h4>
                                        <span
                                            className={`${styles.eventStatus} ${event.status ? styles.statusActive : styles.statusInactive}`}
                                        >
                    {event.status ? 'Active' : 'Inactive'}
                </span>
                                    </div>
                                    <p className={styles.eventDescription}>{event.description}</p>
                                    <div className={styles.eventFooter}>
                                        <span className={styles.eventPoints}>{event.points} pts</span>
                                        <span className={styles.eventTime}>{new Date(event.time).toLocaleString()}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{color: 'var(--silver)', textAlign: "center"}}>No events yet</div>
                        )}

                    </div>
                </>
            }
        </>
    );
}


export default function () {
    const [events, setEvents] = useState([])
    const [podium, setPodium] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const latest = await Latest();
            //const podium = await LeaderBoard()
            //setPodium(podium.global.topThree)
            setEvents(latest);
        }

        fetchData();
    },[])
    return(<>
            <LatestEvents events={events}/>
            <h1 className="title">Leaderboard</h1>
            <LeaderboardPage/>

        {/*<SuggestionNav/>*/}

        </>
    )
}
