export async function Read(id = null) {
    const response = await fetch(`https://bingo.redata.app/api/trophies${id ? '?user_id=' + id : ''}`, {
        method: 'GET', // Use GET for reading data
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.json();
}
