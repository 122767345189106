const API_BASE_URL = "https://bingo.redata.app/api"; // Replace with your actual API URL

const LeagueAPI = {
    // Fetch all leagues (with optional search query)
    getLeagues: async (query = "") => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues?query=${query}`);
            return await response.json();
        } catch (error) {
            console.error("Error fetching leagues:", error);
            return { status: "error", message: error.message };
        }
    },

    // Fetch a single league by ID
    getLeagueById: async (leagueId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues/${leagueId}`);
            return await response.json();
        } catch (error) {
            console.error("Error fetching league:", error);
            return { status: "error", message: error.message };
        }
    },

    // Create a new league
    createLeague: async (leagueData) => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(leagueData),
            });
            return await response.json();
        } catch (error) {
            console.error("Error creating league:", error);
            return { status: "error", message: error.message };
        }
    },

    // Edit an existing league
    editLeague: async (leagueId, leagueData) => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues/${leagueId}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(leagueData),
            });
            return await response.json();
        } catch (error) {
            console.error("Error editing league:", error);
            return { status: "error", message: error.message };
        }
    },

    // Delete a league
    deleteLeague: async (leagueId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues/${leagueId}`, {
                method: "DELETE",
            });
            return await response.json();
        } catch (error) {
            console.error("Error deleting league:", error);
            return { status: "error", message: error.message };
        }
    },

    // Join a league
    joinLeague: async (userId, leagueId) => {
        console.log(`${API_BASE_URL}/leagues/join`);
        console.log({ user_id: userId, league_id: leagueId });
        try {
            const response = await fetch(`${API_BASE_URL}/leagues/join`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user_id: userId, league_id: leagueId }),
            });
            return await response.json();
        } catch (error) {
            console.error("Error joining league:", error);
            return { status: "error", message: error.message };
        }
    },

    // Get the league a user is currently part of
    getUserLeague: async (userId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/userLeague?user_id=${userId}`);
            return await response.json();
        } catch (error) {
            console.error("Error fetching user's league:", error);
            return { status: "error", message: error.message };
        }
    },

    getLeagueLeaderboard: async (leagueId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/leagues/leaderboard/${leagueId}`, {})
            return await response.json();
        }catch (error){
            console.error("Error fetching league table:", error);
            return { status: "error", message: error.message };
        }
    },
    leaveLeague: async (userId) => {
        try{
            const response = await fetch(`${API_BASE_URL}/leaveLeague/${userId}`);
            return await response.json();
        }catch (error){
        console.error("Error fetching league table:", error);
        return { status: "error", message: error.message };
    }
    }
};

export default LeagueAPI;
