import { useState, useEffect } from "react";
import styles from "./CountdownTimer.module.css";
import {NavLink, useNavigation} from "react-router-dom"; // Create this CSS file for styling

export default function CountdownTimer({ startTime, eventName }) {
    const [countdown, setCountdown] = useState("");
    const [active, setActive] = useState(false);
    const {navigate} = useNavigation();

    useEffect(() => {
        if (!startTime) return;

        const updateCountdown = () => {
            const now = new Date();
            const eventStart = new Date(startTime);
            const diff = eventStart - now;

            if (diff > 0) {
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((diff / (1000 * 60)) % 60);
                const seconds = Math.floor((diff / 1000) % 60);
                setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                setActive(false)
            } else {
                setCountdown("Event has started!");
                setActive(true)
            }
        };

        updateCountdown(); // Run once immediately
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, [startTime]);

    if (!startTime) return null;

    return (
        <div className={styles.countdownContainer}>
            <p>Next event: <strong>{eventName}</strong></p>
            <p className={styles.countdown}>Starts in: <strong>{countdown}</strong></p>
            {active&&<NavLink to='/cards'><button style={{marginTop: "10px"}}>To cards</button></NavLink>}
        </div>
    );
}
