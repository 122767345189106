import { useState } from "react";
import HelpModal from "../HelpModal/HelpModal";
import styles from "./BrickRequests.module.css";
import { useAuth } from "../../Context/AuthContext";
import { CreateSubmission } from "../../api/Submissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

export default function BrickRequests() {
    const { id: userId } = useAuth(); // Extract user ID from context
    const [formData, setFormData] = useState({
        suggestion: "",
        author: "",
        userid: userId,
    });
    const [submitted, setSubmitted] = useState(false);
    const [help, setHelp] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setSubmitted(false); // Reset submission status on input change
        setFormData((prev) => ({
            ...prev,
            [id]: value, // Update the corresponding field dynamically
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Input validation
        if (!formData.suggestion.trim() || !formData.author.trim()) {
            alert("Please provide both a suggestion and your name.");
            return;
        }

        try {
            // Submit the form data
            await CreateSubmission(formData);
            setSubmitted(true);
            setError(null); // Clear any previous errors
            setFormData({
                suggestion: "",
                author: "",
                userid: userId, // Reset to current user ID
            });
        } catch (err) {
            console.error("Error creating submission:", err);
            setError("Failed to submit your suggestion. Please try again.");
        }
    };

    return (
        <div className={`${styles.container} Block`}>
            <h1 className={styles.header}>2025 Brick Suggestions <span><FontAwesomeIcon icon={faCircleInfo}
                                                                                        className={"hoverable icon"}
                                                                                        style={{color: "grey"}}
                                                                                        onClick={() => setHelp(true)}/></span>
            </h1>

            <p className={styles.shortDescription}>
                Have a great idea for a new brick? Share it here! Need inspiration? Click the i for more
                details and examples.
            </p>

            <form onSubmit={handleSubmit} className={styles.form}>
                <label htmlFor="author" className={styles.label}>Your Name</label>
                <input
                    id="author"
                    type="text"
                    value={formData.author}
                    onChange={handleInputChange}
                    placeholder="Enter your name..."
                    className={styles.input}
                />
                <label htmlFor="suggestion" className={styles.label}>Your Suggestion</label>
                <textarea
                    id="suggestion"
                    value={formData.suggestion}
                    onChange={handleInputChange}
                    placeholder="Enter your suggestion or request here..."
                    rows="4"
                    className={styles.textarea}
                />
                <div className={styles.actions}>
                    <button type="submit" className={styles.submitButton}>
                        Submit
                    </button>
                </div>
            </form>

            {submitted && (
                <p className={styles.successMessage}>
                    Thank you for your suggestion!
                </p>
            )}

            {error && (
                <p className={styles.errorMessage}>
                    {error}
                </p>
            )}

            {help && (
                <HelpModal
                    title="Details & Examples"
                    description={`Share your ideas for new bricks!

Season-long
Examples: "Leclerc wins Monaco" or "Max 10+ wins."

Race-specific
Examples: "A yellow flag" or "Two retirements."

Multi-race
Examples: "Ferrari podium" or "Norris beats Piastri."

Let’s create unique challenges together!`}
                    closeModal={() => setHelp(false)}
                />
            )}
        </div>
    );
}
