import { useEffect, useState } from "react";
import { Read } from "../../api/User";
import styles from "./BoostDisplay.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faRocket} from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal/Modal";
import HelpModal from "../HelpModal/HelpModal";

export default function BoostDisplay({ id }) {
    const [boost, setBoost] = useState(0);
    const [dialog, setDialog] = useState(false);

    const fetchData = async () => {
        const response = await Read(id);
        setBoost(response.boosts);
    };

    const toggleDialog = () => {
        setDialog((prev) => !prev);
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const description = "Boosts are special rewards that multiply the points earned for a brick.\n" +
        "Each time you complete a bingo, you’ll be rewarded with a boost. The more bingos you finish, the more boosts you’ll earn.\n" +
        "Strategically use your boosts to maximize your points and climb the leaderboard.\n" +
        "Whether you're chasing a personal best or aiming to outscore your rivals, boosts can make all the difference!\n";
    return (
        <div className={`${styles.container} Block `} style={{marginBottom: 0}} >

            <h5><FontAwesomeIcon icon={faRocket}/> Boosts: {boost}  <span><FontAwesomeIcon icon={faCircleInfo} className={"hoverable icon"} style={{color: "grey"}} onClick={toggleDialog}/></span></h5>
            {dialog && (
                <HelpModal title={"Boosts"} closeModal={toggleDialog} description={description} actionLabel={"Buy boosts"} actionMethod={()=>console.log("boost baught")}/>
            )}


        </div>
    );
}
