import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LeaguesPage.module.css";
import LeagueAPI from "../../api/LeagueAPI";
import { useAuth } from "../../Context/AuthContext";
import Modal from "../../components/Modal/Modal";
import CreateLeague from "../../components/CreateLeague/CreateLeague";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faSearch, faUsers} from "@fortawesome/free-solid-svg-icons";

export default function LeaguesPage() {
    const { id: userId } = useAuth();
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [userLeague, setUserLeague] = useState(null);
    const [create, setCreate] = useState(false);

    const navigate = useNavigate();

    function fetchLeagues() {
        setLoading(true);
        LeagueAPI.getLeagues(search).then(setLeagues).finally(() => setLoading(false));
    }

    function fetchUserLeague() {
        if (userId) {
            LeagueAPI.getUserLeague(userId).then(setUserLeague).catch(() => setUserLeague(null));
        }
    }

    useEffect(() => {
        fetchLeagues();
        fetchUserLeague();
    }, []);

    return (
        <div className={styles.container}>
            {/* Page Header */}
            <div className={styles.header}>


                <div className={styles.actions}>
                    <></>
                    {/* Search Bar */}
                    <div className={styles.searchContainer}>
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search leagues..."
                            style={{margin: 0}}
                        />
                        <button onClick={fetchLeagues} className={styles.searchButton}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>

                    {/* User League Status / Create League */}
                    {userId && (
                        <div className={styles.userLeague}>
                            {userLeague ? (<div className="Block" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                                    <p>Current: </p>
                                    <button
                                        className={styles.leagueButton}
                                        onClick={() => navigate(`/leagues/${userLeague.id}`)}
                                    >
                                        {userLeague.name}
                                    </button>
                                </div>
                            ) : (
                                <button className={styles.createButton} style={{marginTop: "10px", marginRight: "10px"}} onClick={() => setCreate(true)}>
                                    <FontAwesomeIcon icon={faPlus} /> Create League
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* League List */}
            <div className={styles.leagueList}>
                {loading ? (
                    <p className={styles.loading}>Loading leagues...</p>
                ) : leagues.length > 0 ? (
                    leagues.map((league) => (
                        <div key={league.id} className="Block" style={{display: "flex", justifyContent: "space-between"}}
                        onClick={()=>navigate(league.id.toString())}
                        >
                            <div className={styles.leagueDetails}>
                                <h3>{league.name}</h3>
                                <div>{league.member_count || 0} <FontAwesomeIcon icon={faUsers}/></div>
                            </div>
                            {!userLeague && userId && <button className={styles.joinButton} onClick={()=>{LeagueAPI.joinLeague(userId, league.id).then(r => navigate(league.id))}}>Join</button>}

                        </div>
                    ))
                ) : (
                    <p className={styles.noLeagues}>No leagues found.</p>
                )}
            </div>

            {/* Create League Modal */}
            {create && (
                <Modal closeModal={() => setCreate(false)}>
                    <CreateLeague />
                </Modal>
            )}
        </div>
    );
}
