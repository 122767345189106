import { APP_VERSION } from '../version';

const VersionComponent = () => {
    return (
        <div
            style={{
                width: "100%",
                textAlign: "center",
                padding: "10px 0",
                //backgroundColor: "#f8f9fa", // Light background for better visibility
                fontSize: "1rem", // Subtle font size
                color: "#6c757d", // Muted text color
                //borderTop: "1px solid #e9ecef", // Separator for a clean look
                fontFamily: "Arial, sans-serif", // Clean font
            }}
        >
            GrandPrixBingo • Version {APP_VERSION}
        </div>
    );
};

export default VersionComponent;
