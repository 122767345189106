import styles from './LeaderBoard.module.css';
import Flag from "../Flag";

export function Leaderboard({ data, title, setCard }) {
    const { topThree, currentUser } = data;

    // Helper function to determine rank style
    const getRankClass = (rank) => {
        if (rank === 1) return styles.rankGold;
        if (rank === 2) return styles.rankSilver;
        if (rank === 3) return styles.rankBronze;
        return styles.rankDefault;
    };

    return (
        <div className={styles.container}>
            {/* Title with Flag if available */}
            <div className={styles.header}>
                <h2 className={styles.title}>{title}</h2>
                {data.code && <Flag code={data.code} />}
            </div>

            <div className={styles.table}>
                {topThree.length > 0 ? (
                    <>
                        {topThree.map((user) => (
                            <div
                                key={user.id}
                                className={`${styles.row} ${user.id === currentUser?.id ? styles.currentUser : ''}`}
                                onClick={() => setCard(user.card_id)}
                            >
                                <div className={`${styles.rank} ${getRankClass(user.rank)}`}>
                                    #{user.rank}
                                </div>
                                <div className={styles.content}>
                                    <span className={styles.name}>{user.user_name}</span>
                                    {user.name && <span className={styles.league}>{user.name}</span>}
                                </div>
                                <div className={styles.points}>{user.total_points} pts</div>
                            </div>
                        ))}

                        {/* If currentUser is NOT in the top 3, show them below */}
                        {!topThree.some(user => user.id === currentUser?.id) && currentUser && (
                            <>
                                <div className={styles.ellipsis}>...</div>
                                <div
                                    className={`${styles.row} ${styles.currentUser}`}
                                    onClick={() => setCard(currentUser.card_id)}
                                >
                                    <div className={`${styles.rank} ${getRankClass(currentUser.rank)}`}>
                                        #{currentUser.rank}
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.name}>{currentUser.user_name}</span>
                                        {currentUser.name && <span className={styles.league}>{currentUser.name}</span>}
                                    </div>
                                    <div className={styles.points}>{currentUser.total_points} pts</div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    // If topThree is empty, just show the current user
                    currentUser ? (
                        <div
                            className={`${styles.row} ${styles.currentUser}`}
                            onClick={() => setCard(currentUser.card_id)}
                        >
                            <div className={`${styles.rank} ${getRankClass(currentUser.rank)}`}>
                                #{currentUser.rank}
                            </div>
                            <div className={styles.content}>
                                <span className={styles.name}>{currentUser.user_name}</span>
                                {currentUser.name && <span className={styles.league}>{currentUser.name}</span>}
                            </div>
                            <div className={styles.points}>{currentUser.total_points} pts</div>
                        </div>
                    ) : (
                        <div style={{ color: 'var(--silver)' }}>No registered bingo cards yet</div>
                    )
                )}
            </div>
        </div>
    );
}
