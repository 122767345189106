// Submissions.js

// Read all submissions
export async function ReadAllSubmissions() {
    try {
        const response = await fetch("https://bingo.redata.app/api/submissions", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching submissions:", error);
        return [];
    }
}

// Read a single submission by ID
export async function ReadSubmissionById(submissionId) {
    try {
        const response = await fetch(`https://bingo.redata.app/api/submissions/${submissionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching submission with ID ${submissionId}:`, error);
        return null;
    }
}

// Create a new submission
export async function CreateSubmission(submissionData) {
    console.log(JSON.stringify(submissionData))
    try {
        const response = await fetch("https://bingo.redata.app/api/submissions", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submissionData),
        });
        const data = await response.json();
        return data.id
    } catch (error) {
        console.error("Error creating submission:", error);
        throw error;
    }
}

// Update an existing submission by ID
export async function UpdateSubmission(submissionId, updatedData) {
    try {
        const response = await fetch(`https://bingo.redata.app/api/submissions/${submissionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error updating submission with ID ${submissionId}:`, error);
        throw error;
    }
}

// Delete a submission by ID
export async function DeleteSubmission(submissionId) {
    try {
        const response = await fetch(`https://bingo.redata.app/api/submissions/${submissionId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            return { success: true };
        } else {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to delete submission');
        }
    } catch (error) {
        console.error(`Error deleting submission with ID ${submissionId}:`, error);
        throw error;
    }
}
