export default function Flag({ code, size=40}) {
        if (!code) return null; // Handle missing or undefined codes

        // Generate the URL for the flag image
        const flagUrl = `https://flagcdn.com/64x48/${code.toLowerCase()}.png`;

        return (
            <img
                src={flagUrl}
                alt={`${code} flag`}
                style={{ display: 'inline-block' }}
            />
        );
}
